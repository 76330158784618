import * as React from 'react'
import { useInView } from 'react-intersection-observer'

type HeroProps = {
  title?: string
  content?: string
  image?: React.ReactNode
}


export default function Hero(props: HeroProps) {

  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0.35,
    triggerOnce: true,
  })

  return (
    <section
      className={`section--hero relative overflow-hidden lg:overflow-visible bg-theme-dark pt-32 ${inView && 'is-revealed'
        }`}
      ref={ref}

    >
      <div className="container--padded">
        <div className="section--hero__inner flex flex-wrap">
          <div className="section--hero__image" >
            <div className="image image--full">{props.image}</div>
          </div>
          <div className="section--hero__content" >
            <div className="inner" >

              <h1 className="heading" >{props.title}</h1>

              <p className="content text-white max-w-2xl">{props.content}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
