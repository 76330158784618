import * as React from "react"
import { useState } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { EffectFade } from "swiper"
import { graphql, Link, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import _ from "lodash"
import "swiper/swiper-bundle.min.css"

SwiperCore.use([EffectFade])

type ServiceControllerItemProps = {
  isCurrentSlide: boolean
  index: number
  title: string
  onClick: React.MouseEventHandler<HTMLButtonElement>
  [x: string]: any
}
const ServiceControllerItem = (props: ServiceControllerItemProps) => {
  return (
    <button
      className={`control__item ${props.isCurrentSlide && "is--currentSlide"}`}
      data-target={props.index}
      onClick={props.onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="50px"
        viewBox="0 0 146.09 94.49"
      >
        <defs></defs>
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <path
              className="cls-1"
              d="M144.55,69.7,115.11,48.35l-5.5,7.59,15.86,11.51-77.19-.59a.73.73,0,0,1,0,.2,13.48,13.48,0,0,0-.7-1.49L67.24,43.75A11.75,11.75,0,0,0,82.62,34.2c0-.06,0-.12,0-.18,0-.25,0-.5.06-.76s0-.33,0-.5,0-.1,0-.16,0-.26,0-.4,0-.32,0-.49l0-.19c0-.14,0-.28-.05-.43s-.07-.52-.12-.78L82.45,30c-.07-.3-.14-.59-.23-.88a.88.88,0,0,1,0-.15c-.11-.33-.22-.64-.35-1l0-.06c-.14-.33-.3-.66-.47-1l0,0c-.18-.32-.36-.63-.56-.93l0-.05q-.3-.43-.63-.84l-.09-.1c-.21-.25-.42-.49-.65-.72l-.17-.16c-.21-.2-.42-.4-.64-.58l-.28-.22c-.19-.15-.39-.31-.59-.45l-.4-.25c-.18-.11-.36-.23-.54-.33l-.52-.27-.47-.22-.64-.25-.4-.15-.76-.22-.32-.08c-.3-.07-.6-.12-.91-.17l-.2,0c-.36,0-.73-.07-1.1-.09h0l-.4,0a11.73,11.73,0,0,0-8.58,3.73l-39-13.72a11.7,11.7,0,1,0-3.1,8.82l39,13.7a11.81,11.81,0,0,0,1.17,4.4L40.19,59.91a11.83,11.83,0,0,0-2.75-.33A11.69,11.69,0,1,0,48.27,75.64a.63.63,0,0,1-.21.56l76.45.59L108.25,86.4l4.8,8.09L142.62,77l.73-.42.88-.54.15-.09a4,4,0,0,0,1.6-2.27A3.85,3.85,0,0,0,144.55,69.7Z"
            />
          </g>
        </g>
      </svg>
      {props.title}
    </button>
  )
}
type ServiceSwiperSlideProps = {
  image: React.ReactNode
  title: string
  content: string
  to: string
  [x: string]: any
}
const ServiceSwiperSlide = (props: ServiceSwiperSlideProps) => {
  return (
    <div className="slide__inner block--service">
      <div className="block__image"> {props.image} </div>
      <div className="block__content">
        <h3 className="heading"> {props.title} </h3>
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: props.content }}
        />
        {props.to ? (
          <div className="action mt-8">
            <Link to={props.to} className="btn btn--secondary">
              {" "}
              Find Out More{" "}
            </Link>
          </div>
        ) : null}
      </div>
    </div>
  )
}

interface IServiceObj {
  name: string
  image?: string
  content: string
}

const ServiceObj: IServiceObj[] = [
  {
    name: "SEO",
    content:
      "<p>Sed augue ipsum,<br> egestas nec, vestibulum et, malesuada adipiscing, dui. Phasellus dolor. Vivamus elementum semper nisi.</p>",
    image: "imageSEO",
  },
  {
    name: "Digital Advertising",
    content:
      "Vivamus euismod mauris. Aenean tellus metus, bibendum sed, posuere ac, mattis non, nunc. Praesent congue erat at massa.",
    image: "imagePPC",
  },
  {
    name: "Google Ads",
    content:
      "Aliquam eu nunc. Donec sodales sagittis magna. Nunc sed turpis. Vivamus quis mi.",
    image: "imageSocial",
  },
  {
    name: "Remarketing",
    content:
      "VEtiam ut purus mattis mauris sodales aliquam. Mauris turpis nunc, blandit et, volutpat molestie, porta ut, ligula. Nam commodo suscipit quam. Aliquam lobortis.",
    image: "imageRemarketing",
  },
]
interface IContent {
  name: string
  image?: string
  content: string
  to?: string
}
type SwiperProps = {
  content: IContent[]
}

export default function ServiceSwiper({ content, ...props }: SwiperProps) {
  const [swiper, setSwiper] = useState(null)
  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0)
  const handleClickButton =
    (index: number) =>
    (e: React.MouseEvent<HTMLElement>): void => {
      // console.log('clicked', e.currentTarget.getAttribute('data-target'))
      swiper.slideTo(index)
      setCurrentSlideIndex(index)
    }
  const ServiceImagesData = useStaticQuery(graphql`
    {
      imageSEO: file(relativePath: { eq: "malaysia-top-seo-expert.png" }) {
        childImageSharp {
          gatsbyImageData(width: 600, quality: 100, layout: CONSTRAINED)
        }
      }
      imagePPC: file(
        relativePath: { eq: "malaysia-top-google-adwords-ads-expert.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 600, quality: 100, layout: CONSTRAINED)
        }
      }
      imageRemarketing: file(
        relativePath: { eq: "digital-marketing-remarketing.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 600, quality: 100, layout: CONSTRAINED)
        }
      }
      imageSocial: file(
        relativePath: { eq: "malaysia-top-social-media-marketing-expert.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 600, quality: 100, layout: CONSTRAINED)
        }
      }
      imageWebsite: file(
        relativePath: { eq: "malaysia-top-website-design-expert.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 600, quality: 100, layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <>
      <div className="section-service__slider">
        <div className="slider--services__control">
          <div className="control__inner ">
            {_.map(content, (o, i) => {
              return (
                <ServiceControllerItem
                  key={i}
                  index={i}
                  onClick={handleClickButton(i)}
                  title={o.name}
                  isCurrentSlide={currentSlideIndex === i}
                />
              )
            })}
          </div>
        </div>
        <Swiper
          effect="fade"
          fadeEffect={{ crossFade: true }}
          spaceBetween={50}
          slidesPerView={1}
          onSwiper={s => setSwiper(s)}
          onSlideChange={() => console.log("slide change")}
          className="slider slider--services"
        >
          {_.map(content, (o, i) => {
            return (
              <SwiperSlide className="slider--services__slide" key={i}>
                <ServiceSwiperSlide
                  title={o.name}
                  content={o.content}
                  image={
                    <GatsbyImage
                      image={
                        ServiceImagesData[o.image].childImageSharp
                          .gatsbyImageData
                      }
                      alt={``}
                    />
                  }
                  to={o.to}
                />
              </SwiperSlide>
            )
          })}
        </Swiper>
      </div>
    </>
  )
}
