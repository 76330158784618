import * as React from 'react'

type TestimonialFullwidthProps = {
  testimonial: string
  author: string
}
export default function TestimonialFullwidth(props: TestimonialFullwidthProps) {
  return (
    <section className="section--testimonial pt-resp-16-48 pb-resp-16-48 bg-theme-dark">
      <div className="container--padded ">
        <div className="inner max-w-5xl md:px-8 2xl:px-12  text-center mx-auto">
          <blockquote className="testimonial  font-body text-lg leading-normal">
            {props.testimonial}
          </blockquote>
          <p className="author mt-6 lg:mt-10 text text-sm leading-snug">
            {props.author}
          </p>
        </div>
      </div>
    </section>
  )
}
