import * as React from 'react'
import {useInView} from 'react-intersection-observer'

type SidebysideHeadingContentOverlapImageProps = {
  image?: React.ReactNode
  heading?: string
  heading2?: string
  content?: string
}
export default function SidebysideHeadingContentOverlapImage(
  props: SidebysideHeadingContentOverlapImageProps,
) {
  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0,
    triggerOnce: true,
  })
  return (
    <section
      className={`section--sbs-hc-over-i   pt-resp-32-56  pb-resp-16-48 overflow-hidden ${
        inView && 'is-revealed'
      }`}
      ref={ref}
    >
      <div className="container--padded">
        <div className="inner flex">
          <div className="content-wrapper">
            <div className="image">{props.image}</div>
            <div className="intro ">
              <h2 className="heading max-w-2xl">
                <span className="line-1">{props.heading}</span>
                <span className="line-2">{props.heading2}</span>
              </h2>
              <p className="text max-w-3xl">{props.content}</p>
            </div>
          </div>
          <div className="empty-wrapper"></div>
        </div>
      </div>
    </section>
  )
}
