import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"
import Hero from "../components/sections/Hero"
import { default as SbsHCI } from "../components/sections/SidebysideHeadingContentOverlapImage"
import TestimonialFullwidth from "../components/sections/TestimonialFullwidth"
import CaseStudy from "../components/sections/CaseStudy"
import CallToAction from "../components/sections/CallToAction"
import SwiperAccordion from "../components/SwiperAccordion"
import { graphql } from "gatsby"
import Banner from "../components/Banner"

const windowGlobal = typeof window !== "undefined" && window

const IndexPage = props => {
  const [width, setWidth] = useState(windowGlobal.innerWidth)
  useEffect(() => {
    function handleResize() {
      setWidth(windowGlobal.innerWidth)
    }
    windowGlobal.addEventListener("resize", handleResize)
    return () => windowGlobal.removeEventListener("resize", handleResize)
  }, [width])

  const featuredWork = props.data.featuredWork.edges[0].node
  const featuredWorkImage = getImage(featuredWork.banner)
  useEffect(() => {
    console.log(
      `%c     ___         ___         ___      ___         ___         ___               `,
      "color: red; font-size: 15px;line-height:1"
    )
    console.log(
      `%c    /\\  \\       /\\  \\       /\\  \\    /\\  \\       /\\  \\       /\\  \\        ___   `,
      "color: red; font-size: 15px;line-height:1"
    )
    console.log(
      `%c   /::\\  \\     /::\\  \\     /::\\  \\   \\:\\  \\     /::\\  \\     /::\\  \\      /\\  \\  `,
      "color: red; font-size: 15px;line-height:1"
    )
    console.log(
      `%c  /:/\\:\\  \\   /:/\\:\\  \\   /:/\\ \\  \\   \\:\\  \\   /:/\\:\\  \\   /:/\\:\\  \\     \\:\\  \\ `,
      "color: red; font-size: 15px;line-height:1"
    )
    console.log(
      `%c /::\\~\\:\\__\\ /::\\~\\:\\  \\ _\\:\\~\\ \\  \\  /::\\  \\ /::\\~\\:\\  \\ /::\\~\\:\\  \\    /::\\__\\ `,
      "color: red; font-size: 15px;line-height:1"
    )
    console.log(
      `%c/:/\\:\\ \\:|__/:/\\:\\ \\:\\__/\\ \\:\\ \\ \\__\\/:/\\:\\__/:/\\:\\ \\:\\__/:/\\:\\ \\:\\__\\__/:/\\/__/`,
      "color: red; font-size: 15px;line-height:1"
    )
    console.log(
      `%c\\:\\~\\:\\/:/  \\:\\~\\:\\ \\/__\\:\\ \\:\\ \\/__/:/  \\/__\\/__\\:\\/:/  \\/_|::\\/:/  /\\/:/  /   `,
      "color: red; font-size: 15px;line-height:1"
    )
    console.log(
      `%c \\:\\ \\::/  / \\:\\ \\:\\__\\  \\:\\ \\:\\__\\/:/  /         \\::/  /   |:|::/  /\\::/__/    `,
      "color: red; font-size: 15px;line-height:1"
    )
    console.log(
      `%c  \\:\\/:/  /   \\:\\ \\/__/   \\:\\/:/  /\\/__/          /:/  /    |:|\\/__/  \\:\\__\\    `,
      "color: red; font-size: 15px;line-height:1"
    )
    console.log(
      `%c  \\:\\/:/  /   \\:\\ \\/__/   \\:\\/:/  /\\/__/          /:/  /    |:|\\/__/  \\:\\__\\       `,
      "color: red; font-size: 15px;line-height:1"
    )
    console.log(
      `%c   \\::/__/     \\:\\__\\      \\::/  /               /:/  /     |:|  |     \\/__/       `,
      "color: red; font-size: 15px;line-height:1"
    )
    console.log(
      `%c    ~~          \\/__/       \\/__/                \\/__/       \\|__|                  `,
      "color: red; font-size: 15px;line-height:1"
    )
  }, [])
  return (
    <>
      <Layout>
        <SEO isIndex={true} />

        <Hero
          title="Digital Marketing Strategy Agency."
          content="Specialize in point-to-point, digital marketing actions driven by data with the sole purpose of improving your business - a predictable and replicable business growth."
          image={
            <StaticImage
              src="../images/kuala-lumpur-digital-vignette.jpg"
              alt="A dinosaur"
            />
            // <GatsbyImage
            //   image={props.data.imageKL.childImageSharp.gatsbyImageData}
            //   style={{ maxHeight: "100%", height: "100%" }} />
          }
        />
        <SbsHCI
          heading="Realistic. Transparent."
          heading2="Result driven."
          image={
            <StaticImage
              src="../images/digital-marketing-strategy-success-dark.png"
              alt="A dinosaur"
            />
          }
          content={`There are huge opportunities to be captured online, and making your way through the digital marketing landscape may always feel like you're stuck in a labyrinth packed with technical buzzwords, vague markers, and questionable tactics. That's why we have dedicated ourselves to be your compass. A Kuala Lumpur-based digital marketing strategist, not just another "digital marketing agency." By analysing your current situation we examine your options and implement the point-to-point strategy. But mostly, we get there by listening. To you, your data and your customers`}
        />

        <SwiperAccordion
          isDark={true}
          content={[
            {
              name: "SEO",
              content:
                "<p>Don't fall for SEO strategies that aren't intended to last, sustainable SEO brings predictable cash flow to your business.</p><p>When all is said and done, our best in class organic marketing method delivers a prominent bottom line value.a carefully chosen blend of human intelligence and technological expertise is the cornerstone of our incredible record</p>",
              image: "imageSEO",
              to: "/service/seo/",
            },
            {
              name: "Google Adwords",
              content:
                "<p>As Kuala Lumpur’s leading independent Google Certified partner, there is nothing more rewarding for us to our clients than their fantastic bottom-line growth.</p><p> We have placed a great emphasis on cultivating the best-in-class industry experts and cutting-edge technologies to deliver exceptional returns on your investment. By incorporating our solid methodology to the mix, we have mastered the blend that enables you to reach the right audience in Google at the right time for sustainable business growth.</p>",
              image: "imagePPC",
              to: "/service/google-adwords-management/",
            },
            {
              name: "Social Media",
              content:
                "<p>Intelligent remarketing enhances brand associations and intent in a customer's mind; strategic brand placement aims at the precise moment your customers are motivated to act.</p><p> Our experts understand behavioural data, as well as consumer intent and perceptions of brand attitudes. We also understand that each customer is different, so we can apply creative messaging to further strengthen your brand. <p>",
              image: "imageSocial",
              to: "/service/social-media-marketing/",
            },
            {
              name: "Website Design",
              content:
                "<p>We are a digital marketing agency composed of web specialists who deliver customised projects with an emphasis on business results rather than creative awards. Your customers are the cornerstones of our web projects.</p><p> We place heavy emphasis on creating search engine-friendly, compelling copywriting, to keep your visitors happy and satisfied, which enhances your overall online brand. <p>",
              image: "imageWebsite",
              to: "/service/website-design/",
            },
          ]}
        />
        <TestimonialFullwidth
          testimonial={`Let me get back to you tomorrow'. I still remember the quote that
          he said after our first meeting. I was sceptical at first, but
          Bestari and the team surprised us with detailed possible solutions
          we could go with for each stages of our business to ensure our
          online success. Very thorough and solid, we look forward to
          working with them again`}
          author={`Kenneth L, Head of Marketing, DECATHLON AU.`}
        />
        <CaseStudy
          title={featuredWork.title}
          content={featuredWork.description}
          image={
            <GatsbyImage
              image={featuredWorkImage}
              className="stats__fimage"
              alt={`case study of ${featuredWork.title}`}
            />
          }
          to={`/work/${featuredWork.slug}`}
          stats={featuredWork.stats}
        />

        <CallToAction />
      </Layout>
    </>
  )
}

export default IndexPage

export const pageQuery = graphql`
  {
    featuredWork: allContentfulPastWorks(
      filter: { markAsFeatured: { eq: true } }
    ) {
      edges {
        node {
          id
          title
          slug
          description
          date: createdAt(formatString: "MMMM DD, YYYY")
          banner: bannerImage {
            gatsbyImageData(layout: FULL_WIDTH)
          }
          stats {
            number
            description
          }
        }
      }
    }
  }
`
