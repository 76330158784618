import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import _ from "lodash"

interface IServiceObj {
  name: string
  image?: string
  content: string
}

const ServiceObj: IServiceObj[] = [
  {
    name: "SEO",
    content:
      "<p>Sed augue ipsum,<br> egestas nec, vestibulum et, malesuada adipiscing, dui. Phasellus dolor. Vivamus elementum semper nisi.</p>",
    image: "imageSEO",
  },
  {
    name: "Digital Advertising",
    content:
      "Vivamus euismod mauris. Aenean tellus metus, bibendum sed, posuere ac, mattis non, nunc. Praesent congue erat at massa.",
    image: "imagePPC",
  },
  {
    name: "Google Ads",
    content:
      "Aliquam eu nunc. Donec sodales sagittis magna. Nunc sed turpis. Vivamus quis mi.",
    image: "imageSocial",
  },
  {
    name: "Remarketing",
    content:
      "VEtiam ut purus mattis mauris sodales aliquam. Mauris turpis nunc, blandit et, volutpat molestie, porta ut, ligula. Nam commodo suscipit quam. Aliquam lobortis.",
    image: "imageRemarketing",
  },
]
interface IContent {
  name: string
  image?: string
  content: string
  to?: string
}
type SwiperProps = {
  content: IContent[]
}
const ServiceAccordion = ({ content, ...props }: SwiperProps) => {
  const ServiceImagesData = useStaticQuery(graphql`
    {
      imageSEO: file(relativePath: { eq: "malaysia-top-seo-expert.png" }) {
        childImageSharp {
          gatsbyImageData(width: 300, quality: 100, layout: CONSTRAINED)
        }
      }
      imagePPC: file(
        relativePath: { eq: "malaysia-top-google-adwords-ads-expert.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 300, quality: 100, layout: CONSTRAINED)
        }
      }
      imageRemarketing: file(
        relativePath: { eq: "digital-marketing-remarketing.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 300, quality: 100, layout: CONSTRAINED)
        }
      }
      imageSocial: file(
        relativePath: { eq: "malaysia-top-social-media-marketing-expert.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 300, quality: 100, layout: CONSTRAINED)
        }
      }
      imageWebsite: file(
        relativePath: { eq: "malaysia-top-website-design-expert.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 300, quality: 100, layout: CONSTRAINED)
        }
      }
    }
  `)

  const [state, setState] = useState({ selectedService: 0 })

  const toggleVisibility = i => e => {
    if (i == state.selectedService) {
    } else {
      setState({
        ...state,
        selectedService: i,
      })
    }
  }
  return (
    <>
      <ul className="accordion accordion--services ">
        {_.map(content, (service, i) => {
          return (
            <li className="service__accordion-group">
              <button
                className={`accordion-group__trigger ${
                  state.selectedService == i ? "is--open" : ""
                } `}
                data-id={i}
                onClick={toggleVisibility(i)}
              >
                <span className="text">{service.name}</span>
                <span className="chevron">
                  <svg
                    className="fill-current text-white w-4 h-auto object-contain"
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    id="Capa_1"
                    x="0px"
                    y="0px"
                    viewBox="0 0 256 256"
                  >
                    <g>
                      <g>
                        <polygon points="225.813,48.907 128,146.72 30.187,48.907 0,79.093 128,207.093 256,79.093   " />
                      </g>
                    </g>
                  </svg>
                </span>
              </button>
              <div
                className={`accordion-group__panel ${
                  state.selectedService == i ? "is--open" : ""
                }`}
              >
                <div className="block--service">
                  <div className="block__image relative z-0">
                    <GatsbyImage
                      image={
                        ServiceImagesData[service.image].childImageSharp
                          .gatsbyImageData
                      }
                      alt={``}
                    />
                    <div className="absolute h-40 w-full bottom-0 left-0 lg:hidden from-transparent bg-gradient-to-b to-theme"></div>
                  </div>
                  <div className="block__content -mt-24 relative z-10">
                    <h3 className="heading">{service.name}</h3>
                    <div
                      className="content"
                      dangerouslySetInnerHTML={{ __html: service.content }}
                    />
                    {service.to ? (
                      <div className="action mt-5">
                        <Link to={service.to} className="btn btn--secondary">
                          Find Out More{" "}
                        </Link>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </li>
          )
        })}
      </ul>
    </>
  )
}

export default ServiceAccordion
