import * as React from 'react'
import { useEffect, useState } from 'react'
import ServiceAccordion from './ServiceAccordion'
import ServiceSwiper from './ServiceSwiper'

interface IContent {
  name: string
  image?: string
  content: string
  to?: string
}



type SwiperAccordionProps = {
  content: IContent[];
  className?: string;
  isDark?: boolean;
}

export default function SwiperAccordion({
  content,
  className,
  isDark,
  ...props
}: SwiperAccordionProps) {
  const windowGlobal = typeof window !== 'undefined' && window
  const [windowWidth, setWindowWidth] = useState<number>(
    windowGlobal.innerWidth,
  )
  useEffect(() => {
    function handleResize() {
      setWindowWidth(windowGlobal.innerWidth)
    }
    windowGlobal.addEventListener('resize', handleResize)
    return windowGlobal.removeEventListener('resize', handleResize)
  }, [windowWidth])
  return (
    <>
      <section className={`section-swiper-accordion ${isDark ? 'bg-theme-dark' : ''} ${className ? className : ''}`} >

        {windowWidth > 960 && (
          <div className=" pl-offset pr-6 lg:pr-0">
            < ServiceSwiper content={content} />
          </div>
        )}
        {windowWidth <= 959 && (
          <div className=" px-2 ">
            <ServiceAccordion content={content} />
          </div>
        )}

      </section>
    </>
  )
}
