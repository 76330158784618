import * as React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import _ from 'lodash'

interface IStatsObject {
  number: number
  description: string
}

type CaseStudyProps = {
  title: string
  content: React.ReactNode
  image: React.ReactNode
  stats: IStatsObject[]
  to: string
}

export default function CaseStudy(props: CaseStudyProps) {
  const imageQuery = useStaticQuery(graphql`
    {
      imageCs: file(relativePath: {eq: "cs1.jpg"}) {
        childImageSharp {
          gatsbyImageData(width: 500, quality: 100, layout: CONSTRAINED)
        }
      }
      imageStatsCs: file(relativePath: {eq: "cs1.jpg"}) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      imageRemarketing: file(
        relativePath: {eq: "digital-marketing-remarketing.png"}
      ) {
        childImageSharp {
          gatsbyImageData(width: 600, quality: 100, layout: CONSTRAINED)
        }
      }
      imageSocial: file(
        relativePath: {eq: "digital-marketing-social-media.png"}
      ) {
        childImageSharp {
          gatsbyImageData(width: 600, quality: 100, layout: CONSTRAINED)
        }
      }
      imageWebsite: file(
        relativePath: {eq: "digital-marketing-website-design.png"}
      ) {
        childImageSharp {
          gatsbyImageData(width: 600, quality: 100, layout: CONSTRAINED)
        }
      }
    }
  `)
  return (
    <section className="cs section--case-study bg-theme overflow-hidden">
      <div className="-mx-8">
        <div className="pl-offset pr-8 md:pr-0">
          <div className="case-study__inner flex flex-wrap items-center">
            <div className="case-study__col ">
              <div className="case-study__excerpt">
                <h3 className="title">{props.title}</h3>
                <p className="text">{props.content}</p>

                <Link to={props.to} className="btn btn--secondary btn--wide">
                  See Full Case Study
              </Link>
              </div>
            </div>
            <div className="case-study__col">
              <div className="case-study__fimage">{props.image}</div>
            </div>
            <div className="case-study__col">
              {props.image}
              <ul className="case-study__stats">
                {_.map(props.stats, (stat, i) => {
                  return (
                    <li key={i} className="block--stat">
                      <div className="stat__num">
                        <span className="text">{stat.number}
                      <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 150 83.87"
                          >
                            <defs></defs>
                            <g id="Layer_2" data-name="Layer 2">
                              <g id="Layer_1-2" data-name="Layer 1">
                                <path
                                  className="cls-1"
                                  d="M149.67,5.71l.23-1h0l0-.17h0a3.9,3.9,0,0,0-.51-2.73A3.81,3.81,0,0,0,145.59.05h0L109.75,6.18l1.58,9.24,19.32-3.3L93.19,49.58a11.63,11.63,0,0,0-8.53,0L67.06,32a11.7,11.7,0,1,0-21.78,0L16,61.28a11.69,11.69,0,1,0,6.61,6.6l29.3-29.29a11.73,11.73,0,0,0,8.53,0L78,56.19a11.7,11.7,0,1,0,21.78,0l36.85-36.86-4.48,18.35,9.14,2.22,8.15-33.38"
                                />
                              </g>
                            </g>
                          </svg>
                        </span>

                      </div>
                      <p className="stat__text">{stat.description}</p>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
